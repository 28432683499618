
.agile {
	position: relative;
}
.agile--rtl .agile__track,
.agile--rtl .agile__slides,
.agile--rtl .agile__actions,
.agile--rtl .agile__dots {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}
.agile:focus, .agile:active, .agile *:focus, .agile *:active {
	outline: none;
}
.agile__list {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.agile__track {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
}
.agile__actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
}
.agile__slides {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
	-ms-flex-negative: 0;
	    flex-shrink: 0;
	-ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
}
.agile--disabled .agile__slides {
	display: block;
}
.agile__slide {
	display: block;
	-webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
	-ms-flex-negative: 0;
	    flex-shrink: 0;
}
.agile--fade .agile__slide {
	opacity: 0;
	position: relative;
	z-index: 0;
}
.agile--fade .agile__slide--active {
	opacity: 1;
	z-index: 2;
}
.agile--fade .agile__slide--expiring {
	opacity: 1;
	-webkit-transition-duration: 0s;
	        transition-duration: 0s;
	z-index: 1;
}
.agile__nav-button[disabled] {
	cursor: default;
}
.agile__dots {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	padding: 0;
	white-space: nowrap;
}
.agile__dot button {
	cursor: pointer;
	display: block;
	font-size: 0;
	line-height: 0;
}


/*# sourceMappingURL=chunk-vendors.e54dcb85.css.map*/